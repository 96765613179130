
import React, { useState, useRef, useEffect } from 'react';
import './BulkUserCreation.scss';
import { UpoloadIconSvg, CloseIconSvg, XlsIconSvg, XlsxIconSvg, InfoWhiteIconSvg, Button } from "../../global";
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { CALL_NOTIFY } from "../../global/store/action";
import CONST from "../../locale.json";
import { api } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PopUpModel } from "../../global/components/PopUpModel";
import { RootState } from "../../reducer";
import * as XLSX from 'xlsx';

interface User {
    rowNumber: number;
    userName: string;
    emailID: string;
    firstName: string;
    lastName: string;
    userType: string;
    projectType: string;
    projectName: string;
}

interface UserError {
    user: User;
    error: string;
}
interface Projects {
    projectName: string;
    projectType: string;
}
interface Project {
    id: string;
    name: string;
    appType: string;
}
const header =
    [{
        "rowno": "Row no",
        "userName": "Username",
        "error": "Error",
    }];

const BulkUserCreation: React.FC = () => {
    const [fileName, setFileName] = useState<string | null>(null);
    const [fileSize, setFileSize] = useState<number | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [pendingUsers, setPendingUsers] = useState<User[]>([]);
    const [errorMessages, setErrorMessages] = useState<UserError[]>([]);
    const [isFileValid, setIsFileValid] = useState<boolean>(true);
    const dispatch = useDispatch();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const descObj: Record<string, string> = {
        default: "Please correct the entries displayed below and upload the file again to add the entities.",
        no_error: "There were no errors found"
    };


    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const handleFileUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const generateXLS = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Template", {
                pageSetup: { paperSize: 9, orientation: "landscape" },
            });

            worksheet.columns = [
                { header: 'Username (username@domain.com)', key: 'userName', width: 35 },
                { header: 'First name', key: 'firstName', width: 15 },
                { header: 'Last name', key: 'lastName', width: 15 },
                { header: 'Role', key: 'role', width: 15 },
                { header: 'Project type', key: 'projectType', width: 15 },
                { header: 'Project name (Use "," to separate multiple projects (e.g., Project1, Project2))', key: 'projectName', width: 70 },
            ];

            worksheet.addRow({ userName: '', firstName: '', lastName: '', role: '', projectType: '', projectName: '' });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'template.xlsx');
        } catch (err) {
            console.error('Error generating Excel file:', err);
        }
    };
    const validateFile = async (file: File) => {
        try {
            const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as ArrayBuffer);
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            });

            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            let headersValid = false;

            if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });

                const validHeaders = [
                    'Username (username@domain.com)',
                    'First name',
                    'Last name',
                    'Role',
                    'Project type',
                    'Project name (Use "," to separate multiple projects (e.g., Project1, Project2))'
                ];

                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const headerValues: string[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0] as string[];

                // Triming and normalizing headers for case-insensitive comparison
                const normalizedHeaderValues = headerValues.map(header => header.trim().toLowerCase());
                const normalizedValidHeaders = validHeaders.map(header => header.toLowerCase());

                headersValid = normalizedHeaderValues.length === normalizedValidHeaders.length;

                if (!headersValid) {
                    setUploadStatus('Invalid input. Please ensure that the uploaded file adheres to the required file and template format.');
                    setIsFileValid(false);
                    return false;
                }
            }

            setValidationMessage('');
            return true;
        } catch (err) {
            setUploadStatus('Invalid input. Please ensure that the uploaded file adheres to the required file and template format.');
            setIsFileValid(false);
        }
    };

    const validateUser = async (user: User, duplicate_users: Set<string>, projects: Project[], existing_user: string[], fetchprojects: string[], fetchrole: string[]): Promise<UserError | null> => {
        const errors: any[] = [];

        const {
            userName,
            firstName,
            lastName,
            userType,
            projectType,
            projectName
        } = user;

        user.userName = user.userName.toLowerCase();
        const NAME_REGEX = /^(?=.*[a-zA-Z0-9].*[a-zA-Z0-9])(?!.*_*__)[\w]+$/;
        // Check for missing required fields

        if (!userName) {
            errors.push("Username is missing");
            return {
                user,
                error: errors.join(', ')
            };
        } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,}$/.test(userName)) {
            errors.push("Username must be in the format 'username@domain.com'");
            return {
                user,
                error: errors.join(', ')
            };
        }

        // Validate firstName with NAME_REGEX
        if (!firstName) errors.push("First name is missing");
        else if (!NAME_REGEX.test(firstName)) {
            errors.push("First name must contain atleast two alphanumeric characters");
        } else if (firstName.length > 50) {
            errors.push("First name can contain upto 50 characters including alphabets, numbers and underscore");
        }

        // Validate lastName with NAME_REGEX (only if provided)
        if (lastName !== undefined && lastName !== "") {
            if (lastName.length > 50) {
                errors.push("Last name can contain up to 50 characters including alphabets, numbers, and underscore");
            }
        }

        if (!userType) {
            errors.push("Role type is missing")
        }
        else {
            let isValidRole = false;

            fetchrole.forEach((role: any) => {
                if (role.name === userType) {
                    isValidRole = true;
                }
            });

            if (!isValidRole) {
                errors.push(`Invalid role type: ${userType}`);
            }
        }


        //Check for duplicates
        if (duplicate_users.has(userName)) {
            errors.push(`Duplicate entry`);
            return {
                user,
                error: errors.join(', ')
            };
        }
        duplicate_users.add(userName);

        if (userType === 'admin' && (projectType || projectName)) {
            return {
                user,
                error: "Admin cannot be created with project type and project name"
            };
        }

        if (userType !== "admin") {
            if (projectName) {
                // Normalize user input to lower case for case-insensitive comparison
                const normalizedProjectType = projectType ? projectType.trim().toLowerCase().split(',').map(pt => pt.trim()) : [];
                const validProjectTypes = ['web', 'api', 'sap']; // valid project types
                const invalidProjectTypes = normalizedProjectType.filter(pt => !validProjectTypes.includes(pt.trim()));
                if (!projectType) {
                    errors.push("Project type must be either 'web', 'api', or 'sap'.");
                } else if (invalidProjectTypes.length > 0) {
                    errors.push(`Invalid project type(s): ${invalidProjectTypes.join(', ')}`);
                }
                const projectNamesArray = projectName.split(',').map(name => name.trim());

                const projectExists = projectNamesArray.every(project =>
                    fetchprojects.some((fetchedProject) => (fetchedProject as any).name === project)
                );

                if (!projectExists) {
                    errors.push("One or more projects do not exist");
                }

            }
        }

        const userExists = existing_user.find((user: any) => user.userName === userName.toLowerCase());
        userExists && errors.push(`User already exists`)

        // Return error if there are any
        if (errors.length > 0) {
            return {
                user,
                error: errors.join(', ')
            };
        }

        return null;
    };
    const parseExcelFile = async (file: File) => {
        try {
            const existing_user = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSER, { params: `/` });
            let appType = "";
            let params = `all/${appType}`;
            const fetchprojects = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHPROJECT, { params: params });
            const fetchrole = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHROLE, { params: `/` });

            const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as ArrayBuffer);
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            });

            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            const users: User[] = [];
            const errors: UserError[] = [];
            const duplicateUsers = new Set<string>();

            if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                interface Row {
                    'Username (username@domain.com)': string;
                    'First name': string;
                    'Last name': string;
                    'Role': string;
                    'Project type': string;
                    'Project name (Use "," to separate multiple projects (e.g., Project1, Project2))': string;
                }

                const rows: Row[] = XLSX.utils.sheet_to_json(worksheet);

                for (let rowNumber = 0; rowNumber < rows.length; rowNumber++) {
                    const row = rows[rowNumber];

                    const user = {
                        userName: row['Username (username@domain.com)'] || '',
                        emailID: row['Username (username@domain.com)'],
                        firstName: row['First name'],
                        lastName: row['Last name'] || '',
                        userType: row['Role'],
                        projectType: row['Project type'],
                        projectName: row['Project name (Use "," to separate multiple projects (e.g., Project1, Project2))'],
                        rowNumber: rowNumber + 2
                    };

                    const error = await validateUser(user, duplicateUsers, [], existing_user.data.data, fetchprojects.data.data, fetchrole.data);
                    if (error) {
                        errors.push(error);
                    } else {
                        users.push(user);
                    }
                }
            }

            return { validUsers: users, errors };
        } catch (err) {
            setValidationMessage('Failed to parse Excel file.');
            return { validUsers: [], errors: [] };
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setIsFileSelected(true);

        if (file) {
            if (fileInputRef.current && fileInputRef.current.files?.[0] === file) {
                fileInputRef.current.value = '';
                fileInputRef.current.files = null;
            }
            setFileName(file.name);
            setFileSize(file.size);
            setIsUploading(true);
            setUploadStatus(null);
            setErrorMessages([]);

            const interval = setInterval(() => {
                setUploadProgress(prevProgress => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        setIsUploading(false);

                        (async () => {
                            if (await validateFile(file)) {
                                const { validUsers, errors } = await parseExcelFile(file);
                                if (errors.length > 0) {
                                    setErrorMessages(errors);
                                    setPendingUsers(validUsers);
                                    setUploadStatus('Uploaded with errors')
                                    // setShowDeleteConfirm(true)
                                    setHasErrors(true);
                                    dispatch({
                                        type: CALL_NOTIFY,
                                        payload: {
                                            type: "ERROR",
                                            msg: `${errors.length} user(s) have errors.`,
                                        },
                                    });
                                } else if (validUsers.length === 0) {
                                    // If the file is empty or has no valid users
                                    setUploadStatus('Invalid input. Please ensure that the uploaded file adheres to the required file and template format.');
                                    setHasErrors(true);
                                } else {
                                    setPendingUsers(validUsers);
                                    setUploadStatus('Successfully uploaded');
                                    setHasErrors(false);
                                    // setHasErrors(false);
                                }
                            } else {
                                setUploadStatus('Invalid input. Please ensure that the uploaded file adheres to the required file and template format.');
                                setIsFileValid(false);

                            }
                        })();
                        return prevProgress;
                    }
                    return prevProgress + 10;
                });
            }, 200);

        }
    };
    const processFile = async (users: User[]) => {
        try {
            const formData = new FormData();
            formData.append('users', JSON.stringify(users));

            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CREATEUSER, { body: users, params: `/bulk-upload` }, dispatch);
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

            const userCount = resp.data.length;
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: `${userCount} user(s) created successfully!` } });

            for (let i = 0; i < users.length; i++) {
                const createdUser = resp.data[i];
                const userID = createdUser.id;
                const projectName = users[i].projectName;

                if (projectName && typeof projectName === 'string') {
                    const projectNames = projectName.split(',').map(name => name.trim());

                    // Only assign the project if projectName is valid and not empty
                    if (projectNames[0] !== '') {
                        const userProjectResp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.ASSIGNPROJECT, { params: "user", body: { userID, projectName: projectNames } }, dispatch);

                        if (userProjectResp.err) {
                            return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: userProjectResp.err || userProjectResp.msg } });
                        }
                    }
                }
            }



        } catch (err) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: err } });
            setUploadStatus('Error processing file.');
        }
    };

    const handleRemoveFile = () => {
        setFileName(null);
        setFileSize(null);
        setIsUploading(false);
        setUploadProgress(0);
        setUploadStatus(null);
        setValidationMessage(null);
        setErrorMessages([]);
        if (fileInputRef.current) {
            fileInputRef.current.files = null;
            fileInputRef.current.value = '';
        }
    };

    const handleSubmit = async () => {

        if (hasErrors) {
            setShowDeleteConfirm(true);
        } else {
            try {
                await processFile(pendingUsers);
                navigate(devMode ? "/edituser" : "/admin/edituser");

            } catch (error) {
                dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Failed to create user" } });
            }
        }
    };

    const handleConfirmSubmit = async () => {
        setShowDeleteConfirm(false);

        try {
            // Process the users if there are any
            if (pendingUsers.length === 0) {
                const userCount = pendingUsers.length;
                if (userCount === 0) {
                    dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "No valid entries" } });

                }
                return;
            }

            await processFile(pendingUsers);
            navigate(devMode ? "/edituser" : "/admin/edituser");

        } catch (error) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Failed to create user" } });
        }
    };
    const formatFileSize = (size: number) => {
        if (size < 1024) {
            return `${size} bytes`;
        } else if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} KB`;
        } else {
            return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        }
    };

    return (
        <div className="container">
            <div className='tab_name_sec'>
                <h2 className='tabname'>Bulk user creation</h2>
            </div>
            <div className="upload-section">
                <div className='step1_section'>
                    <h2 className='heading_num'>Step 1</h2>
                    <h2 className='description'>
                        <p><a href="#download" onClick={generateXLS}>Click here</a> to download the file</p>
                    </h2>
                </div>

                <div className='step2_section'>
                    <h2 className='heading_num'>Step 2</h2>
                    <h4 className='description'>Upload the completed template file below</h4>
                    <div>
                        <h2 className='allowed_name'>Allowed file formats:.xls,.xlsx</h2>
                    </div>
                    <div className="file-upload-box" title=''>
                        <input
                            type="file"
                            id="file-upload"
                            accept=".xlsx,.xls"
                            onChange={handleFileChange}
                            ref={fileInputRef}

                        />
                        <img className='upload_img' src={UpoloadIconSvg} alt="Upload Icon" onClick={handleFileUploadClick} />
                        <label htmlFor="file-upload" >
                            Drag and drop here or
                            <span>click here to select files</span>
                        </label>
                    </div>
                </div>
                {fileName && (
                    <div className="file-info">
                        <div className='file-container'>
                            {/* Conditionally rendering the icons based on file extension as xls or xlsx */}
                            {fileName.toLowerCase().endsWith('.xls') ? (
                                <img className='pdf-icon' src={XlsIconSvg} alt="XLS Icon" />
                            ) : fileName.toLowerCase().endsWith('.xlsx') ? (
                                <img className='xlsx-icon' src={XlsxIconSvg} alt="XLSX Icon" />
                            ) : null}
                            <div className="file-name" title={fileName}>
                                {fileName}
                            </div>
                            <div><span><img className='remove-file' onClick={handleRemoveFile} src={CloseIconSvg} alt="Cancel Icon" /></span></div>
                        </div>
                        <div className="upload-progress-container">
                            <div
                                className={`upload-progress`}
                                style={{ width: `${uploadProgress}%`, backgroundColor: uploadStatus?.toLowerCase().includes('input') ? '#D32F2F' : 'green' }} // Green if valid, red if invalid
                            ></div>
                            {uploadStatus ? (
                                <div className={`upload-status ${uploadStatus.toLowerCase().includes('error') ||
                                    uploadStatus.toLowerCase().includes('failed') || uploadStatus.toLowerCase().includes('input')
                                    ? 'error'
                                    : 'success'}`}
                                >
                                    {uploadStatus}
                                </div>
                            ) : (
                                <div className="file-size">
                                    {fileSize !== null ? formatFileSize(fileSize) : ''}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="bulk-user-btn">
                <Button title={"Submit"} className="savebtn" onClick={handleSubmit} disabled={!uploadStatus || uploadStatus.toLowerCase().includes('invalid input')} />
            </div>
            <div className='border_line'></div>
            <div className="error-section">
                <h2 className='error_name'>Error details</h2>
                {uploadStatus === 'Successfully uploaded' ? (
                    <div className='error_message'>
                        <img src={InfoWhiteIconSvg} alt="info-icon" />
                        <p className='message_box'>{descObj.no_error}</p>
                    </div>
                ) : (
                    <div className='error_message'>
                        <img src={InfoWhiteIconSvg} alt="info-icon" />
                        <p className='message_box'>{descObj.default}</p>
                    </div>
                )}
                {errorMessages.length > 0 ? (
                    <div className='detailed_err'>
                        <table className='error-table'>
                            <thead>
                                <tr>
                                    <th className='row_no'>Row no.</th>
                                    <th className='user_name'>Identifier</th>
                                    <th className='err_name'>Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                {errorMessages.map((error, index) => (
                                    <tr key={index}>
                                        <td className='row_num'>{error.user.rowNumber}</td>
                                        <td className='user_name' title={error.user.emailID}>{error.user.emailID}</td>
                                        <td className='err_name' title={error.error}>{error.error}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className='no_error_message'>
                    </div>
                )}
            </div>
            {
                showDeleteConfirm && (
                    <PopUpModel
                        Title="Warning"
                        onClickClose={() => setShowDeleteConfirm(false)} // Close popup without action
                        onSaveClick={handleConfirmSubmit}
                        screenBlock
                        closeTitle="Cancel"
                        saveTitle="Continue"
                    >
                        <div className="body-content dds-body">Only valid entries will be added. Incomplete entries or entries with errors will be omitted. </div>
                    </PopUpModel>
                )
            }
        </div >
    );
};

export default BulkUserCreation;


