import { useRef, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CONST from "../../locale.json";
import { api } from "../../api";
import { CALL_NOTIFY } from "../../global/store/action";
import { RootState } from "../../reducer";
import { Button, AdminAPIIcon, AdminDesktopIcon, AdminSAPIcon, AdminWebIcon, AdminDatabaseIcon } from "../../global";
import { SELECTEDPARENT, SELECTEDLEAF } from "../store/action";
import "./EditProject.scss";

const appType =
    [{ "name": "Web", "icon": "web" },
    { "name": "SAP", "icon": "sap" },
    { "name": "API", "icon": "api" },
    { "name": "Database", "icon": "database" },
    { "name": "Desktop", "icon": "desktop" }
    ];
export const EditProject = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [editScreen, setEditScreen] = useState(true);
    const [editRenameProject, setEditRenameProject] = useState(true);
    const [renameProject, setRenameProject] = useState<string>("");
    const projectnameRef = useRef<HTMLInputElement>(null)
    const renameProjectnameRef = useRef<HTMLInputElement>(null);
    const selectedProject = useSelector((state: RootState) => state.adminReducer.selectedProject);
    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);
    const [nextEnabled, setNextEnabled] = useState(false)
    const NAME_REGEX = /^(?=.*[a-zA-Z0-9].*[a-zA-Z0-9])(?!.*_*__)[\w]+$/;

    useEffect(() => {
        dispatch({ type: SELECTEDPARENT, payload: 'Project management' })
        dispatch({ type: SELECTEDLEAF, payload: 'PROJECTLIST' })
    }, [])

    const updateProject = async (id: string) => {
        // update project api
        if (editScreen) {
            if (renameProjectnameRef.current) {
                if (renameProjectnameRef.current?.value === "") {
                    return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Please enter the project name" } });
                }
                else if (!NAME_REGEX.test(renameProjectnameRef.current?.value as any)) {
                    return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Project name is invalid" } });
                }
                else if ((renameProjectnameRef.current?.value.length as any) < 2) {
                    return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Project name must have minimum 2 characters" } });
                }
            }
            if (selectedProject.name === renameProject || renameProject.length == 0) {
                dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Project '" + renameProject + "' already exists" } });
                return
            }
            setEditScreen(false);
            setEditRenameProject(false);
        }
        else {
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATEPROJECT, {
                body: {
                    name: renameProjectnameRef.current?.value,
                    appType: selectedProject.appType
                }, params: id
            }, dispatch)
            if (resp.err) {
                setEditScreen(true);
                setEditRenameProject(true);
                return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            }
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Project has been successfully updated" } });
            navigate(devMode ? "/projectlist" : "/admin/projectlist", { replace: true })
        }
    }

    const getTargetIcon = useCallback((app: string) => ({
        web: AdminWebIcon,
        sap: AdminSAPIcon,
        api: AdminAPIIcon,
        database: AdminDatabaseIcon,
        desktop: AdminDesktopIcon
    }[app]), []);

    const getTargetIconAltText = useCallback((app: string) => ({
        web: "WebIcon",
        sap: "SAPIcon",
        api: "APIIcon",
        database: "DatabaseIcon",
        desktop: "DesktopIcon"
    }[app]), []);

    const setName = useCallback((i: EventTarget & HTMLInputElement) => {
        setNextEnabled(true)
        setRenameProject(i.value.replace(/[^a-zA-Z0-9_]/g, ""));
    }, []);

    return (<div className=" edit-project-container dds-body ">
        <h3 className="dds-h5-lm">Edit project</h3>
        {editScreen ?
            <div className="edit-Project-div">
                <label>Project name</label>
                <div className="edit-apptype-select">
                    <input ref={projectnameRef} value={selectedProject.name} className={"admin-textbox disable"} readOnly={true} type="text" />
                </div>
            </div>
            : null}
        <div className="edit-Project-div">
            <label>{editRenameProject ? "Rename project" : "Project name"}</label>
            {editRenameProject ?
                <div className="edit-apptype-select">
                    <input aria-label="Update project name" ref={renameProjectnameRef} value={renameProject} className={"admin-textbox"} onChange={(e) => setName(e.target)} type="text" maxLength={50} />
                </div> : <div className="edit-apptype-select">
                    <input ref={renameProjectnameRef} value={renameProject} className={"admin-textbox disable"} type="text" maxLength={50} disabled={true} />
                </div>}
        </div>
        <div className="edit-select-app-div">
            <label>App type</label>
        </div>
        <div className="edit-select-app">
            {appType.map((e) => (<div className={(e.name === selectedProject.appType) ? "selectedApp edit-apptype-select" : "edit-apptype-select"} >
                {/* <div>
                    <input className="radio" name="radio" type="radio" checked={selectedProject.appType === e.name} />
                </div> */}
                <img src={getTargetIcon(e.icon)} alt={getTargetIconAltText(e.icon)}></img>
                <label>{e.name}</label>
            </div>))}
        </div >
        <div className="edit-project-btn" role="button">
            {editScreen ?
                <Button title="Back" className="--savebtn" style={{ backgroundColor: 'white', color: 'green' }} onClick={() => navigate(devMode ? "/projectlist" : "/admin/projectlist")} />
                : null}
            <Button title={editScreen ? "Next" : "Update"} className="--savebtn" onClick={() => updateProject(selectedProject.id)} disabled={!nextEnabled || renameProject.length == 0} />
        </div>
    </div >)
}