
import { useState, useRef, FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Dropdown, PassInfoIconSvg } from "../../global";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDPARENT, SELECTEDLEAF } from "../store/action"
import { api } from "../../api";
import { ToolTip } from "./ToolTip";
import CONST from "../../locale.json";
import './SSOConfig.scss';
import { ClickAwayListener } from "@mui/material";

const DropdownIndicatorStyle = {
    color: CONST.COLOR.HEADERCOLOR,
    ":hover": {
        color: CONST.COLOR.HEADERCOLOR,
    }
}
const DropdownStyle = {
    border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    borderRadius: "0px",
    boxShadow: "none",
    ":hover": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    ":active": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    fontWeight: 500,
};
const placeholderStyle = {
    color: CONST.COLOR.BORDERBLACK,
};
interface SSOConfigI {
    data?: any,
    goBack?: () => void
}
export const SSOConfig: FC<SSOConfigI> = ({ data, goBack }) => {
    const [page, setPage] = useState("page1");
    const [entityId, setEntityId] = useState<string>("");
    const [ssoServiceUrl, setSsoServiceUrl] = useState<string>("");
    const [clientId, setClientId] = useState("")
    const [tokenUrl, setTokenUrl] = useState<string>("");
    const [authUrl, setAuthUrl] = useState<string>("")
    const [clientSecret, setClientSecret] = useState<string>("")
    const [isExists, setIsExists] = useState<boolean>(false)
    const ssoServiceUrlRef = useRef<HTMLInputElement>(null)
    const clientIdRef = useRef<HTMLInputElement>(null)
    const tokenUrlRef = useRef<HTMLInputElement>(null)
    const authUrlRef = useRef<HTMLInputElement>(null)
    const clientSecretRef = useRef<HTMLInputElement>(null)
    const [enabled, setEnabled] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [ssoConfig, setSsoConfig] = useState<any>({});
    const [redirectUri, setRedirectUri] = useState<string>('')
    const [toolTip, setToolTip] = useState<boolean[]>([false, false, false, false, false, false]);
    const [editMode, setEditMode] = useState<boolean>(false)
    const [values, setValues] = useState<Record<"label" | "value", string>[]>([{ label: "", value: "" }])
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [initialConfig, setInitialConfig] = useState<any>({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SELECTEDLEAF, payload: 'SSOCONFIG' })
        dispatch({ type: SELECTEDPARENT, payload: 'SSO configuration' })
    }, [])


    useEffect(() => {
        // Update previousConfig when data change
        if (data) {
            setInitialConfig({
                ssoServiceUrl: data.config?.singleSignOnServiceUrl || '',
                clientId: data.config?.clientID || '',
                clientSecret: data.config?.clientSecret || '',
                tokenUrl: data.config?.tokenUrl || '',
                authUrl: data.config?.authorizationUrl || ''
            });
        }
    }, [data]);

    const handleChange = (field: string, value: string) => {
        const newValue = value.trim();
        const oldValue = initialConfig[field] || ''; // Get initial value from state

        setIsChanged(newValue !== oldValue); // Check if there is a change in the field
    };



    const validate = () => {

        if (page === 'page1') {
            if (selectedOption === "") {
                return "Please select provider";
            }
            // else if (aliasRef.current?.value === "") {
            //     return "Please give Alias name";
            // }
            else {
                return "valid";
            }
        }
        if (page === 'page2' && selectedOption === "saml") {
            if (ssoServiceUrlRef.current?.value === "") {
                return "Please give single signOn service url from azure active directory endpoints";
            }
            else {
                return "valid";
            }
        }
        if (page === 'page2' && selectedOption === "oidc") {
            if (clientIdRef.current?.value === "") {
                return "Please give client id of azure active directory app";
            }
            if (clientSecretRef.current?.value === "") {
                return "Please give client secret of azure active directory app";
            }
            if (tokenUrlRef.current?.value === "") {
                return "Please give token url from azure active directory endpoints";
            }
            if (authUrlRef.current?.value === "") {
                return "Please give authorization url from azure active directory endpoints";
            }
            else {
                return "valid";
            }
        }
    }

    const updateSSO = async () => {
        let ssoConfig = {
            providerId: selectedOption,
            displayName: "Login with SSO",
            // alias: aliasRef.current?.value,
            enabled: enabled,
            config: {}
        }
        if (selectedOption === "saml") {
            ssoConfig.config = {
                singleSignOnServiceUrl: ssoServiceUrlRef.current?.value,
            }
            // Check if there are changes in SAML configuration
            if (!isChanged) {
                return; // Exit function if no changes
            }
            if (isExists) {
                const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATESSOCONFIG, { body: { saml: ssoConfig }, params: "saml" }, dispatch)
                if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while configuring SAML" } });
            }
        }
        else if (selectedOption === "oidc") {
            ssoConfig.config = {
                clientID: clientIdRef.current?.value,
                tokenUrl: tokenUrlRef.current?.value,
                authorizationUrl: authUrlRef.current?.value,
                clientSecret: clientSecretRef.current?.value
            }
            if (!isChanged) {
                return; // Exit function if no changes
            }
            if (isExists) {
                const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATESSOCONFIG, { body: { oidc: ssoConfig }, params: "oidc" }, dispatch)
                if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while configuring OIDC" } });
            }
        }
        setIsChanged(false);
        dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "SSO configuration has been successfully updated" } });
    }
    const creatSSO = async () => {
        let valid = validate();
        if (valid !== "valid") return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: valid } });
        if (page === 'page1') {
            let ssoConfig = {
                providerId: selectedOption,
                displayName: "Login with SSO",
                // alias: aliasRef.current?.value,
                enabled: enabled,
                config: {}
            }
            setSsoConfig(ssoConfig)
            setPage('page2')
        }
        else {
            if (selectedOption === "saml") {
                ssoConfig.config = {
                    singleSignOnServiceUrl: ssoServiceUrlRef.current?.value,
                }
                if (isExists) {
                    const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATESSOCONFIG, { body: { saml: ssoConfig }, params: "saml" }, dispatch)
                    if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while configuring SAML" } });
                }
                else {
                    const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CREATESSOCONFIG, { body: { saml: ssoConfig }, params: "saml" }, dispatch)
                    if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while updating SAML configs" } });
                }
            }
            else if (selectedOption === "oidc") {
                ssoConfig.config = {
                    clientID: clientIdRef.current?.value,
                    tokenUrl: tokenUrlRef.current?.value,
                    authorizationUrl: authUrlRef.current?.value,
                    clientSecret: clientSecretRef.current?.value
                }
                if (isExists) {
                    const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATESSOCONFIG, { body: { oidc: ssoConfig }, params: "oidc" }, dispatch)
                    if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while configuring OIDC" } });
                }
                else {
                    const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CREATESSOCONFIG, { body: { oidc: ssoConfig }, params: "oidc" }, dispatch)
                    if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Error while updating OIDC configs" } });
                }
            }
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "SSO configuration has been successfully created" } });
        }
    }

    const fetchConfig = async (e?: any) => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHSSOCONFIG, {}, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        if (resp.data.length === 0) setValues([{ label: "SAML", value: "saml" }, { label: "OIDC", value: "oidc" }])
        else {
            let options = []
            if (!(resp.data[0].oidc)) {
                options.push({ label: "OIDC", value: "oidc" })
            }
            if (!(resp.data[0].saml)) {
                options.push({ label: "SAML", value: "saml" })
            }
            setValues(options)
        }

    }
    const backBtnHandler = () => {
        setSelectedOption('')
        setPage("page1")
    }

    const closeTooltip = () => {
        setToolTip([false, false, false, false, false, false, false, false, false, false])
    }
    useEffect(() => {
        if (page === "page1" && !editMode) {
            fetchConfig()
        }
    }, [page])
    useEffect(() => {
        if (data) {
            setEditMode(true)
            dispatch({ type: SELECTEDLEAF, payload: 'EDITSSOCONFIG' })
            setPage("page2")
            if (data.providerId === "oidc") {
                setEnabled(data.enabled)
                setRedirectUri(data.redirectUrl)
                setClientId(data.config.clientID)
                setTokenUrl(data.config.tokenUrl)
                setAuthUrl(data.config.authorizationUrl)
                setClientSecret(data.config.clientSecret)
                setIsExists(true)
                setSelectedOption("oidc")
            }
            if (data.providerId === "saml") {
                setEnabled(data.enabled)
                setEntityId(data.entityId)
                setRedirectUri(data.redirectUrl)
                setSsoServiceUrl(data.config.singleSignOnServiceUrl)
                setIsExists(true)
                setSelectedOption("saml")
            }
        }
    }, [data])

    const handleSelection = (e: any) => {
        setSelectedOption(e?.value)
    }
    return (
        <div className="SSO-config-container dds-link">
            <h3 className="dds-h5-lm">SSO configuration</h3>
            {page === 'page1' ?
                <>
                    <div className="other-config-div">
                        <label>Provider ID</label>
                        <div className="dropdown-div" title={values.length > 0 ? "" : "SSO has been configured for SAML and OIDC"}>
                            <Dropdown
                                defaultValue={selectedOption === "oidc" ? { label: "OIDC", value: "oidc" } : selectedOption === "saml" ? { label: "SAML", value: "saml" } : { label: "", value: "" }}
                                placeholderStyle={placeholderStyle}
                                controlStyle={DropdownStyle}
                                dropdownIndicatorStyle={DropdownIndicatorStyle}
                                onchange={(e: any) => {
                                    handleSelection(e);
                                }}
                                placeholder="Select configuration"
                                disabled={values.length > 0 ? false : true}
                                showValue={selectedOption && values.length > 0 ? true : false} options={values} />
                        </div>
                        <div className="info">
                            <ClickAwayListener
                                onClickAway={(e: any) => { (toolTip[0]) && setToolTip([!(toolTip[0]), false, false, false, false, false, false, false, false, false]) }}>
                                <img src={PassInfoIconSvg}
                                    onMouseEnter={() => { setToolTip([!(toolTip[0]), false, false, false, false, false, false, false, false, false]) }} />
                            </ClickAwayListener>
                            {toolTip[0] ?
                                <ToolTip text={"Select the protocol type (SAML/OIDC) for user authentication"} />
                                : null}



                        </div>
                    </div>
                    <div className="other-config-div">
                        <label>Enabled</label>
                        <Dropdown
                            defaultValue={enabled ? { "value": "true", "label": "True" } : { "value": "false", "label": "False" }}
                            placeholderStyle={placeholderStyle}
                            style={{ width: "90%" }}
                            controlStyle={DropdownStyle}
                            dropdownIndicatorStyle={DropdownIndicatorStyle}
                            onchange={(e: any) => {
                                setEnabled(prevState => !prevState)
                            }}
                            placeholder="Select"
                            showValue={selectedOption && values.length > 0 ? true : false}
                            options={[{ "value": "true", "label": "True" }, { "value": "false", "label": "False" }]}
                            disabled={values.length > 0 ? false : true}
                        />
                        <div className="info">
                            <ClickAwayListener
                                onClickAway={(e: any) => { (toolTip[1]) && setToolTip([false, !(toolTip[1]), false, false, false, false, false, false, false, false]) }}>
                                <img src={PassInfoIconSvg}
                                    onMouseEnter={() => { setToolTip([false, !(toolTip[1]), false, false, false, false, false, false, false, false]) }} />
                            </ClickAwayListener>
                            {toolTip[1] ?
                                <ToolTip text={"Enable this protocol for login, or disable it for configuration without use"} />
                                : null}
                        </div>
                    </div>

                </> :
                <>
                    {selectedOption === "saml" ?
                        <>
                            <div className="other-config-div">
                                <label>Single sign-on service URL</label>
                                <input className="admin-textbox" ref={ssoServiceUrlRef} defaultValue={ssoServiceUrl} onChange={(e) => handleChange('ssoServiceUrl', e.target.value)}
                                    type="text" />
                                <div className="info">
                                    <ClickAwayListener
                                        onClickAway={(e: any) => { (toolTip[2]) && setToolTip([false, false, !(toolTip[2]), false, false, false, false, false, false, false]) }}>
                                        <img src={PassInfoIconSvg}
                                            onMouseEnter={() => { setToolTip([false, false, !(toolTip[2]), false, false, false, false, false, false, false]) }} />
                                    </ClickAwayListener>
                                    {toolTip[2] ?
                                        <ToolTip text={"Provide the single sign-on service URL from the Azure AD application endpoints"} />
                                        : null
                                    }
                                </div >
                            </div >
                            {
                                redirectUri !== '' ?
                                    <div className="other-config-div">
                                        <label>Redirect URL</label>
                                        <input className="admin-textbox disable" defaultValue={redirectUri} readOnly={true} type="text" />
                                        <div className="info">
                                            <ClickAwayListener
                                                onClickAway={(e: any) => { (toolTip[3]) && setToolTip([false, false, false, !(toolTip[3]), false, false, false, false, false, false]) }}>
                                                <img src={PassInfoIconSvg}
                                                    onMouseEnter={() => { setToolTip([false, false, false, !(toolTip[3]), false, false, false, false, false, false]) }} />
                                            </ClickAwayListener>
                                            {toolTip[3] ?
                                                <ToolTip text={"Copy and paste this URL into the Redirect URL section of the Azure AD application"} />
                                                : null
                                            }
                                        </div >
                                    </div > : null

                            }
                            {
                                entityId !== '' ?
                                    <div className="other-config-div">
                                        <label>Entity ID</label>
                                        <input className="admin-textbox disable" defaultValue={entityId} readOnly={true} type="text" />
                                        <div className="info">
                                            <ClickAwayListener
                                                onClickAway={(e: any) => { (toolTip[4]) && setToolTip([false, false, false, false, !(toolTip[4]), false, false, false, false, false]) }}>
                                                <img src={PassInfoIconSvg}
                                                    onMouseEnter={() => { setToolTip([false, false, false, false, !(toolTip[4]), false, false, false, false, false]) }} />
                                            </ClickAwayListener>
                                            {toolTip[4] ?
                                                <ToolTip text={"Configure this URL as the Application Identifier URL in your Azure AD application"} />
                                                : null
                                            }
                                        </div >
                                    </div > : null
                            }


                        </> :
                        <>
                            <div className="other-config-div">
                                <label>Client ID</label>
                                <input className="admin-textbox" ref={clientIdRef} defaultValue={clientId} onChange={(e) => handleChange('clientId', e.target.value)} type="text" />
                                <div className="info">
                                    <ClickAwayListener
                                        onClickAway={(e: any) => { (toolTip[5]) && setToolTip([false, false, false, false, false, !(toolTip[5]), false, false, false, false]) }}>
                                        <img src={PassInfoIconSvg}
                                            onMouseEnter={() => { setToolTip([false, false, false, false, false, !(toolTip[5]), false, false, false, false,]) }} />
                                    </ClickAwayListener>
                                    {toolTip[5] ?
                                        <ToolTip text={"Configure the Client ID of the Azure AD application"} />
                                        : null}
                                </div>
                            </div>
                            <div className="other-config-div">
                                <label>Client secret</label>
                                <input className="admin-textbox" ref={clientSecretRef} defaultValue={clientSecret} type="text" />
                                <div className="info">
                                    <ClickAwayListener
                                        onClickAway={(e: any) => { (toolTip[6]) && setToolTip([false, false, false, false, false, false, !(toolTip[6]), false, false, false]) }}>
                                        <img src={PassInfoIconSvg}
                                            onMouseEnter={() => { setToolTip([false, false, false, false, false, false, !(toolTip[6]), false, false, false]) }} />
                                    </ClickAwayListener>
                                    {toolTip[6] ?
                                        <ToolTip text={"Configure the client secret of the Azure AD application"} />
                                        : null}
                                </div>
                            </div>
                            <div className="other-config-div">
                                <label>Token URL</label>
                                <input className="admin-textbox" ref={tokenUrlRef} defaultValue={tokenUrl} type="text" />
                                <div className="info">
                                    <ClickAwayListener
                                        onClickAway={(e: any) => { (toolTip[7]) && setToolTip([false, false, false, false, false, false, false, !(toolTip[7]), false, false]) }}>
                                        <img src={PassInfoIconSvg}
                                            onMouseEnter={() => { setToolTip([false, false, false, false, false, false, false, !(toolTip[7]), false, false]) }} />
                                    </ClickAwayListener>
                                    {toolTip[7] ?
                                        <ToolTip text={"Configure the Token URL from the Azure AD application endpoints"} />
                                        : null}
                                </div>
                            </div>
                            <div className="other-config-div">
                                <label>Authorization URL</label>
                                <input className="admin-textbox" ref={authUrlRef} defaultValue={authUrl} type="text" />
                                <div className="info">
                                    <ClickAwayListener
                                        onClickAway={(e: any) => { (toolTip[8]) && setToolTip([false, false, false, false, false, false, false, false, !(toolTip[8]), false]) }}>
                                        <img src={PassInfoIconSvg}
                                            onMouseEnter={() => { setToolTip([false, false, false, false, false, false, false, false, !(toolTip[8]), false]) }} />
                                    </ClickAwayListener>
                                    {toolTip[8] ?
                                        <ToolTip text={"Configure the Authorisation URL from the Azure AD application endpoints"} />
                                        : null}
                                </div>
                            </div>
                            {
                                redirectUri !== '' ?
                                    <div className="other-config-div">
                                        <label>Redirect URL</label>
                                        <input className="admin-textbox disable" defaultValue={redirectUri} readOnly={true} type="text" />
                                        <div className="info">
                                            <ClickAwayListener
                                                onClickAway={(e: any) => { (toolTip[9]) && setToolTip([false, false, false, false, false, false, false, false, false, !(toolTip[9])]) }}>
                                                <img src={PassInfoIconSvg}
                                                    onMouseEnter={() => { setToolTip([false, false, false, false, false, false, false, false, false, !(toolTip[9])]) }} />
                                            </ClickAwayListener>                                            {
                                                toolTip[9] ?
                                                    <ToolTip text={"Configure this URL in the Azure AD application Redirect URL section"} />
                                                    : null
                                            }
                                        </div >
                                    </div > : null
                            }
                        </>
                    }
                </>
            }
            <div className="other-config-btn" role="button">
                {
                    page !== "page1" ?
                        <Button title="Back" style={{ width: "79px", border: '1px solid rgba(38, 137, 13, 1)', background: 'white', color: 'rgba(38, 137, 13, 1)' }} className="--savebtn" onClick={() => { editMode ? goBack && goBack() : backBtnHandler() }} disabled={false} />
                        : null
                }
                <Button title={page === "page1" ? "Next" : editMode ? "Next" : "Create"} style={{ width: "79px" }} className="--savebtn" onClick={editMode ? updateSSO : creatSSO} disabled={page === "page1" ? selectedOption ? false : true : false || (!isChanged)
                } />
            </div>
        </div >
    )

}