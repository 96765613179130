import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CONST from "../../locale.json";
import { CALL_NOTIFY } from "../../global/store/action";
import { api } from "../../api";
import { SELECTEDPARENT, SELECTEDUSER, SELECTEDLEAF } from "../store/action";
import { TableView } from "./TableView";
import { RootState } from "../../reducer";

const header =
    [{
        "firstName": "User profile",
        "userName": "Username",
        "roleID": "Role Name"
    }];
export const UsersList = () => {

    const [userList, setUserList] = useState([]);
    const dispatch = useDispatch();
    const adminRoleID = useSelector((state: RootState) => state.adminReducer.adminRoleID);
    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({ type: SELECTEDLEAF, payload: 'EDITUSER' })
        dispatch({ type: SELECTEDPARENT, payload: 'User and Role Management' })
    })

    const editUser = (userName: string, roleName: string) => {
        // passing userName and userId for updating user
        navigate(devMode ? "/updateuser" : "/admin/updateuser")
        dispatch({ type: SELECTEDUSER, payload: { userName: userName, roleName: roleName } });
    }

    const deleteUser = async (userName: string, roleName: string) => {
        // delete user by username
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.DELETEUSER, { params: `${roleName}/${userName}` }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        fetchUsers()
        dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Profile has been deleted successfully" } });
    }

    const fetchUsers = async () => {
        // fetch user api
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSER, {}, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        resp.data.data.forEach((item: any) => {
            for (let key in item) {
                if (!(Object.keys(header[0]).includes(key))) {
                    if (key === "lastName") item["firstName"] = item["firstName"] + " " + item["lastName"]
                    delete item[key]
                }
                if (key === 'roleID') {
                    // if (item.roleID === resp.data.adminRoleID) item.roleID = "admin";
                    item.roleID = item.roleName
                }
            }
        })
        setUserList(resp.data.data)
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    return (
        <>
            <TableView Title={"Edit user"} searchPlaceHolder={"Search user"} tableData={userList} showSLNo={true} header={Object.values(header[0])} onClickEdit={(e: any) => editUser(e.userName, e.roleID)} onClickDelete={(e: any) => deleteUser(e.userName, e.roleID)} filter="userName" />
        </>
    )
}