
import { useState, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "../../global";
import { api } from "../../api";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDPARENT, SELECTEDLEAF } from "../store/action";
import CONST from "../../locale.json";
import "./NotificationConfig.scss";
import { RootState } from "../../reducer";

const eventType = { "On execution completion": "onExecutionCompletion" };

const DropdownIndicatorStyle = {
    color: CONST.COLOR.HEADERCOLOR,
    ":hover": {
        color: CONST.COLOR.HEADERCOLOR,
    }
}
const DropdownIndicatorStyleEdit = {
    color: CONST.COLOR.HEADERCOLOR,
    ":hover": {
        color: CONST.COLOR.HEADERCOLOR,
    },
    opacity: 1
}

const DropdownStyle = {
    border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    borderRadius: "0px",
    boxShadow: "none",
    ":hover": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    ":active": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    fontWeight: 400,
};

const placeholderStyle = {
    color: CONST.COLOR.BORDERBLACK,
};
interface NotificationConfigInterface {
    userSelected?: Record<string, any> | undefined,
    handleGoBack?: any | undefined
}
export const NotificationConfig: FC<NotificationConfigInterface> = ({ userSelected, handleGoBack }) => {
    const [userList, setUserList] = useState<any>([]);
    const [user, setUser] = useState<any>([]);
    const [userExists, setUserExists] = useState<boolean>(false)
    const [arr, setArr] = useState<any>([]);
    const [outLook, setOutLook] = useState(false);
    const [teams, setTeams] = useState(false);
    const [templateId, setTemplateId] = useState<string>("")
    const [editMode, setEditMode] = useState<Boolean>(false)
    const [created, setCreated] = useState(false)
    const [dropDownVal, setDropDownVal] = useState<Record<"label" | "value", string>>({ label: "", value: "" })
    const adminRoleID = useSelector((state: RootState) => state.adminReducer.adminRoleID);
    const [changesMade, setChangesMade] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SELECTEDLEAF, payload: 'NOTIFICATIONCONFIG' })
        dispatch({ type: SELECTEDPARENT, payload: 'Notification management' })
    }, [])

    const setNotification = async () => {
        //API to set notification
        if (!editMode && !(outLook || teams)) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Please select an application to create a notification" } });
            return;
        }
        if (!editMode && !arr.includes("onExecutionCompletion")) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Please select an event list to create a notification" } });
            return;
        }
        if (user.emailID == null) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Please select User email ID" } });
            return;
        }
        let body = {
            userID: user.id,
            emailID: user.emailID,
            firstName: user.firstName,
            teams: teams,
            outlook: outLook,
            onExecutionCompletion: arr.includes("onExecutionCompletion"),
        }
        if (!userExists) {
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CREATENOTIFICATIONCONFIG, { params: "", body }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Notification created successfully" } });
            setUserList([]);
            setUser([]);
            setUserExists(false)
            setArr([]);
            setOutLook(false);
            setTeams(false);
            setTemplateId("")
            setEditMode(false)
            setDropDownVal({ label: "", value: "" })
            setCreated(true)
        }
        else {
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATENOTIFICATIONCONFIG, { params: `${templateId}`, body: body }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Notification updated successfully" } });
            if (editMode) {
                handleGoBack()
            }
        }

    }
    const NotificationType = (e: any) => {
        const { name, checked } = e.target;
        if (name === 'Teams') {
            setTeams(checked);
        } else if (name === 'Outlook') {
            setOutLook(checked);
        }
        setChangesMade(true);
    };

    const fetchUsers = async (e?: any) => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHNOTIFICATIONNONCONFIGUREDUSERS, { params: "fetchNonConfiguredNotiffUsers" }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

        var users: any = [];
        var name: string
        for (var itr of resp.data) {
            name = `${itr.firstName} ${itr.lastName} (${itr.emailID})`
            users.push({ id: itr.id, label: name, value: itr.firstName, firstName: itr.firstName, userName: itr.userName, roleID: itr.roleID })
        }
        // update state variable
        setUserList(users)
    }

    const select = (e: any) => {
        // if the box is checked then add the eventname in array, else remove the value
        if (e.target.checked) {
            setArr((prev: any) => [...prev, e.target.name])
        } else {
            setArr((state: any) => state.filter((item: any) => item !== e.target.name))
        }
        setChangesMade(true);
    }
    const SelectUser = async (e: any) => {
        setChangesMade(true);
        const respUser = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSER, { params: `${e.roleID === adminRoleID ? "admin" : "user"}/${e.userName}` }, dispatch)
        if (respUser.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (respUser.err || respUser.msg) } });

        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHNOTIFICATIONCONFIG, { params: `${respUser.data.id}` }, dispatch);
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        if (resp.data.length !== 0) {
            setOutLook(resp.data[0].outlook)
            setTeams(resp.data[0].teams)
            setTemplateId(resp.data[0].id)
            let arrEle: any[] = [];
            Object.entries(eventType).forEach((element: any) => {
                if (resp.data[0][element[1]]) {
                    arrEle.push(element[1])
                }
            });
            setUser(respUser.data);
            setUserExists(true);
            setArr(arrEle);
        }
        else {
            setUser(respUser.data);
            setUserExists(false);
            setArr([]);
            setOutLook(false)
            setTeams(false)
            setTemplateId("")

        }
    }
    const handleDefaultUser = async () => {
        if (userSelected) {
            var users: any = []
            setEditMode(true)
            dispatch({ type: SELECTEDLEAF, payload: 'EDITNOTIFICATION' })
            let resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSER, { params: `fetchUserById/${userSelected.userID}` }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            var name: any = `${resp.data[0].firstName} ${resp.data[0].lastName} (${resp.data[0].emailID})`
            users.push({ id: userSelected.userID, label: name, value: userSelected.firstName, firstName: userSelected.firstName, userName: resp.data[0].userName })
            setDropDownVal({ label: name, value: userSelected.firstName })
            setUserList(users)
            setOutLook(userSelected.outlook)
            setTeams(userSelected.teams)
            setTemplateId(userSelected.id)
            let arrEle: any[] = [];
            Object.entries(eventType).forEach((element: any) => {
                if (userSelected[element[1]]) {
                    arrEle.push(element[1])
                }
            });
            setUser({ id: userSelected.userID, emailID: userSelected.emailID, firstName: userSelected.firstName });
            setUserExists(true);
            setArr(arrEle);
        }
    }
    useEffect(() => {
        if (userSelected) {
            handleDefaultUser()
        }
        else {
            if (created) setCreated(false)
            fetchUsers()
        }
    }, [created])

    return (
        <div className="create-Notify-container dds-link">
            <h3 className="dds-h5-lm">Notification configuration</h3>
            <div className="create-Notify-div">
                <label className="lable1" style={{ width: '88px', height: '20px' }}>User email ID</label>
                <div className="search-div">
                    <Dropdown placeholderStyle={placeholderStyle} style={{ width: '90%' }} controlStyle={DropdownStyle} dropdownIndicatorStyle={editMode ? DropdownIndicatorStyleEdit : DropdownIndicatorStyle} defaultValue={userSelected ? dropDownVal : undefined} showValue onchange={SelectUser} options={userList} placeholder="Select" isSearchable reset={created} />
                </div>
            </div>
            <div className="create-Notify-div">
                <label className="lable1" style={{ width: '74px', height: '20px' }}>Application</label>
                <div className="Notify-div">
                    <div>
                        <label className="container">Microsoft Teams
                            <input type="checkbox" className="disable" name="Teams" onChange={(e) => NotificationType(e)} checked={false} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div>
                        <label className="container">Microsoft Outlook
                            <input type="checkbox" name="Outlook" onChange={(e) => NotificationType(e)} checked={outLook} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="create-Notify-div">
                <label className="lable1" style={{ width: '59px' }}>Event list</label>
                <div className="Notify-div">
                    {Object.entries(eventType).map((e) => (
                        <div>
                            <label className="container">{e[0]}
                                <input type="checkbox" name={e[1]} checked={arr.includes(e[1])} onChange={(event) => { select(event) }} style={{ width: '16px' }} className="chkbox" />
                                <span className="checkmark"></span>
                            </label>
                        </div>))}
                </div>
            </div>
            <div className="create-Notify-btn" role="button">
                {editMode && <Button title={"Back"} style={{ backgroundColor: 'white', color: 'green', width: '79px' }} className="--savebtn" onClick={handleGoBack} disabled={false} />}
                <Button title={editMode ? "Update" : "Create"} style={{ width: '80px' }} className="--savebtn" onClick={setNotification} disabled={!(outLook && arr[0] && user.emailID) || !changesMade} />
            </div>
        </div>
    )
}